import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import {
  NotificationType,
  User,
  UserData,
  UserRole,
} from '@/models';
import { AppError } from '@/lib/error';
import { Container } from 'typedi';
import { UserProvider } from '@/provider/api';
import { Either, isLeft, right } from '@/lib/either';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { Router } from 'vue-router';

export const useUserStore = defineStore('users', () => {
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const i18n = useI18n();
  const router = Container.get<Router>('router');
  const userProvider = Container.get(UserProvider);

  const users = reactive<Record<string, User>>({});
  const currentUser = computed(() => users[authStore.userId]);
  const currentUserId = computed(() => authStore.userId);
  const userList = computed(() => Object.values(users));
  const sortedUserList = computed((): User[] => {
    const list = Object.values(users);

    return list.sort((a, b) => {
      if (a.id === authStore.userId) {
        return -1;
      }

      if (b.id === authStore.userId) {
        return 1;
      }

      const aName = a.name || a.email;
      const bName = b.name || b.email;

      return aName.localeCompare(bName);
    });
  });

  function reset() {
    Object.keys(users).forEach((key) => {
      delete users[key];
    });
  }

  function addUser(userData: UserData): User {
    const user = new User(userData);
    users[userData.id] = user;
    return user;
  }

  function getUser(id: string): User | undefined {
    return users[id];
  }

  async function loadUserById(userId: string): Promise<Either<AppError, User>> {
    const result = await userProvider.findUserById(userId);

    if (isLeft(result)) {
      return result;
    }

    const user = addUser(result.right);
    return right(user);
  }

  async function loadUsers(): Promise<Either<AppError, void>> {
    const result = await userProvider.listUsers();

    if (isLeft(result)) {
      return result;
    }

    result.right.forEach((user) => {
      addUser(user);
    });

    return right(undefined);
  }

  async function inviteUser(email: string): Promise<Either<AppError, void>> {
    const result = await userProvider.inviteUser(email);

    if (isLeft(result)) {
      return result;
    }

    addUser(result.right);
    return right(undefined);
  }

  async function updateUserInfo(
    userId: string,
    info: { email?: string, name?: string },
  ): Promise<Either<AppError, void>> {
    const result = await userProvider.updateUserInfo({
      userId,
      ...info,
    });

    if (isLeft(result)) {
      return result;
    }

    addUser(result.right);
    return right(undefined);
  }

  async function updateUserRole(
    userId: string,
    role: UserRole,
  ): Promise<Either<AppError, void>> {
    const result = await userProvider.updateUserRole({
      userId,
      role,
    });

    if (isLeft(result)) {
      return result;
    }

    addUser(result.right);
    return right(undefined);
  }

  async function deleteUser(
    userId: string,
    email: string,
  ): Promise<Either<AppError, void>> {
    const result = await userProvider.deleteUser(userId);

    if (isLeft(result)) {
      return result;
    }

    await router.replace('/team');
    delete users[userId];

    notificationStore.addNotification({
      type: NotificationType.Success,
      title: i18n.t('notification.userDeleted.title'),
      text: i18n.t('notification.userDeleted.text', [email]),
    });

    return right(undefined);
  }

  return {
    users,
    currentUser,
    currentUserId,
    userList,
    sortedUserList,

    getUser,
    loadUserById,
    loadUsers,
    updateUserInfo,
    updateUserRole,
    inviteUser,
    deleteUser,
    reset,
  };
});
