import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Notification, NotificationData } from '@/models';

export const useNotificationStore = defineStore('notification', () => {
  const queue = ref<Notification[]>([]);
  const timers: Record<string, number> = {};

  const removeNotification = (id: string) => {
    queue.value = queue.value.filter((n) => n.id !== id);
    clearTimeout(timers[id]);
    delete timers[id];
  };

  const addNotification = (data: NotificationData) => {
    const notification = new Notification(data);

    if (notification.ttl > 0) {
      timers[notification.id] = setTimeout(() => {
        removeNotification(notification.id);
      }, notification.ttl);
    }

    queue.value = [notification];
  };

  return {
    queue,

    addNotification,
    removeNotification,
  };
});
