<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end mt-16 px-4 py-5 sm:items-start"
  >
    <transition-group
      name="notifications"
      tag="div"
      class="flex w-full flex-col items-center space-y-4 sm:items-end"
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <Notification
        v-for="n in notificationQueue"
        :key="n.id"
        :notification="n"
        @close="handleClose(n.id)"
      />
    </transition-group>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/stores/notification';
import Notification from '@/components/Core/Notification.vue';

const notificationStore = useNotificationStore();

const notificationQueue = computed(() => notificationStore.queue);
const handleClose = (id) => {
  notificationStore.removeNotification(id);
};
</script>
