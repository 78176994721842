import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authRoutes } from '@/router/auth.routes';
import { pageRoutes } from '@/router/pages.routes';
import { useAuthStore } from '@/stores/auth';
import { AuthRouteName, PageRouteName } from '@/router/route.names';

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...pageRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash };
    }

    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const requiresAuth = to.meta.auth;

  if (requiresAuth && !authStore.isAuthenticated) {
    next({ name: AuthRouteName.Login });
    return;
  }

  if (!requiresAuth && authStore.isAuthenticated) {
    next({ name: PageRouteName.Dashboard });
  }

  next();
});

export default router;
