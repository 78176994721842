import { RouteRecordRaw } from 'vue-router';
import PageContainer from '@/components/Layout/PageContainer.vue';
import DashboardPage from '@/components/Dashboard/DashboardPage.vue';
import TeamPage from '@/components/Team/TeamPage.vue';
import UserSettingsPage from '@/components/User/Settings/UserSettingsPage.vue';
import DownloadsView from '@/components/Downloads/DownloadsPage.vue';
import BillingPage from '@/components/Billing/BillingPage.vue';
import { PageRouteName } from '@/router/route.names';

export const pageRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: PageRouteName.Index,
    component: PageContainer,
    children: [
      {
        path: '',
        name: PageRouteName.Dashboard,
        component: DashboardPage,
        meta: {
          auth: true,
        },
      },
      {
        path: 'team',
        name: PageRouteName.Team,
        component: TeamPage,
        meta: {
          auth: true,
        },
      },
      {
        path: 'settings',
        name: PageRouteName.Settings,
        component: UserSettingsPage,
        meta: {
          auth: true,
        },
      },
      {
        path: 'user/:id',
        name: PageRouteName.UserSettings,
        component: UserSettingsPage,
        props: (route) => ({
          userId: route.params.id,
        }),
        meta: {
          auth: true,
        },
      },
      {
        path: 'downloads',
        name: PageRouteName.Downloads,
        component: DownloadsView,
        meta: {
          auth: true,
        },
      },
      {
        path: 'billing',
        name: PageRouteName.Billing,
        component: BillingPage,
        props: (route) => {
          let state: | 'success' | 'fail' | 'upgrade' | undefined;

          switch (true) {
            case 'upgrade' in route.query:
              state = 'upgrade';
              break;
            case 'success' in route.query:
              state = 'success';
              break;
            case 'error' in route.query:
              state = 'fail';
              break;
            default:
              state = undefined;
              break;
          }

          return { state };
        },
        meta: {
          auth: true,
        },
      },
      { path: '/:pathMatch(.*)*', redirect: '/' },
    ],
  },
];
