import { customAlphabet } from 'nanoid';
import { ref } from 'vue';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const size = 9;
const generate = customAlphabet(alphabet, size);

export default function useUniqueId() {
  return ref(generate());
}
