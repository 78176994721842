import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Account } from '@/models';
import { AppError } from '@/lib/error';
import { Container } from 'typedi';
import { Either, isLeft, right } from '@/lib/either';
import { AccountProvider } from '@/provider/api';

export const useAccountStore = defineStore('account', () => {
  const accProvider = Container.get(AccountProvider);
  const account = ref<Account>();

  function reset() {
    account.value = undefined;
  }

  async function getAccountInfo(): Promise<Either<AppError, Account>> {
    const result = await accProvider.getAccountInfo();

    if (isLeft(result)) {
      return result;
    }

    const acc = new Account(result.right);
    account.value = acc;

    return right(acc);
  }

  return {
    account,

    getAccountInfo,
    reset,
  };
});
