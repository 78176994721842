import { Container } from 'typedi';
import createApiConfig from '@/config/api.config';
import createAppConfig from '@/config/app.config';
import { createApiClient } from '@/provider/api/api.client';
import router from '@/router';

const appConfig = createAppConfig();
const apiConfig = createApiConfig();
const apiClient = createApiClient(apiConfig);

Container.set('config.api', apiConfig);
Container.set('api.client', apiClient);
Container.set('config.app', appConfig);
Container.set('router', router);
