<template>
  <div class="loader" role="status">
    <span class="dot" />
    <span class="dot" />
    <span class="dot" />
    <span class="sr-only">Loading...</span>
  </div>
</template>

<style lang="postcss">
@-webkit-keyframes ellipsis-dot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes ellipsis-dot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.loader {
  text-align: center;
  user-select: none;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;

  .dot {
    box-sizing: border-box;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    margin: 0 10%;
    border-radius: 50%;
    background-color: currentColor;
    display: inline-block;
    opacity: 0;
    -webkit-animation: ellipsis-dot .8s infinite;
    animation: ellipsis-dot .8s infinite;

    &:nth-child(1) {
      margin-left: 0;
      -webkit-animation-delay: 0.0s;
      animation-delay: 0.0s;
    }

    &:nth-child(2) {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      margin-right: 0;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
  }
}

</style>
