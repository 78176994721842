export enum RpcMethod {
  UserSignOut = 'user.signOut',
}

export type RpcMessage<T = unknown> = {
  method: RpcMethod,
  params: T;
}

export function useExtensionCall(
  recipientId: string,
) {
  const call = async (msg: RpcMessage): Promise<boolean> => {
    if (!chrome || !chrome.runtime) {
      return false;
    }

    try {
      await chrome.runtime.sendMessage(recipientId, msg);
      return true;
    } catch (err) {
      console.error('Failed to send message to extension', err);
      return false;
    }
  };

  return { call };
}
