import { Service, Inject } from 'typedi';
import { AxiosInstance } from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    raw?: boolean
    withAuth?: boolean
  }
}

@Service()
export class ApiProvider {
  protected readonly http: AxiosInstance;

  constructor(@Inject('api.client') http: AxiosInstance) {
    this.http = http;
  }
}
