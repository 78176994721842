import { createPinia } from 'pinia';
import persistPlugin from 'pinia-plugin-persistedstate';
import { Router } from 'vue-router';
import { I18n } from 'vue-i18n';
import { markRaw } from 'vue';
import router from '@/router';
import i18n from '@/i18n';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router,
    i18n: I18n,
  }
}

const pinia = createPinia();
pinia
  .use(persistPlugin)
  .use((ctx) => {
    const { store } = ctx;
    store.router = markRaw(router);
    store.i18n = i18n;
  });

export default pinia;
