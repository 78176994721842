import 'reflect-metadata';
import './container';
import { AxiosInstance } from 'axios';
import { Container } from 'typedi';
import { createApp } from 'vue';
import i18n from '@/i18n';
import store from '@/stores';
import router from '@/router';
import App from '@/App.vue';
import { useAuthStore } from '@/stores/auth';
import { useRequestInterceptor, useResponseInterceptor } from '@/provider/api';
import { errorInterceptor, authInterceptor } from '@/provider/api/interceptor';
import './styles/app.css';

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n);

const apiClient = Container.get<AxiosInstance>('api.client');
useRequestInterceptor(
  apiClient,
  authInterceptor(useAuthStore()),
  (err) => Promise.reject(err),
);
useResponseInterceptor(
  apiClient,
  (resp) => resp,
  errorInterceptor(),
);

app.mount('#app');
