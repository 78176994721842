import { Currency } from '@/lib/money';

export enum PlanId {
  Free = 'free',
  Pro1MT10D = 'pro.1m.t.10d',
}

export interface PlanData {
  id: PlanId;
  name: string;
  trialUsers: number;
  trialDays: number;
  maxUsers: number;
  price: number;
  currency: Currency;
}

export class Plan {
  id: PlanId;
  name: string;
  trialUsers: number;
  trialDays: number;
  maxUsers: number;
  price: number;
  currency: Currency;

  constructor(data: PlanData) {
    this.id = data.id;
    this.name = data.name;
    this.trialUsers = data.trialUsers;
    this.trialDays = data.trialDays;
    this.maxUsers = data.maxUsers;
    this.price = data.price;
    this.currency = data.currency;
  }

  static oneOfPro(id: PlanId): boolean {
    switch (id) {
      case PlanId.Pro1MT10D:
        return true;
      default:
        return false;
    }
  }

  static defaultPro(): PlanId {
    return PlanId.Pro1MT10D;
  }

  isFree(): boolean {
    return !Plan.oneOfPro(this.id);
  }

  isPro(): boolean {
    return Plan.oneOfPro(this.id);
  }
}
