import { nanoid } from 'nanoid';

const DEFAULT_TTL = 4500;

export enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export interface NotificationData {
  type: NotificationType;
  title: string;
  text?: string;
  ttl?: number;
}

export class Notification {
  id: string;
  type: NotificationType;
  title: string;
  text?: string;
  ttl: number;

  constructor(data: NotificationData) {
    this.id = nanoid();
    this.type = data.type;
    this.title = data.title;
    this.text = data.text;
    this.ttl = data.ttl || DEFAULT_TTL;
  }
}
