export enum ErrorCode {
  Invalid = 'validation_error',
  Permission = 'permission_error',
  Unauthorized = 'auth_error',
  LimitReached = 'limit_error',
  Conflict = 'conflict_error',
  NotFound = 'not_found_error',
  Server = 'server_error',
  Network = 'network_error',
  Internal = 'internal_error',
}

export enum ErrorSub {
  InternalError = 'internal_error',
  ServerError = 'server_error',
}

export class AppError extends Error {
  code: ErrorCode;
  sub: string;

  constructor(code: ErrorCode, sub: string, msg?: string, err?: Error) {
    let m = `${code}`.toUpperCase();

    if (sub.length > 0) {
      m += ` [${sub}]`;
    }

    if (msg && msg.length > 0) {
      m += ` ${msg}`;
    }

    if (err instanceof Error) {
      m += `: ${err.message}`;
    }

    super(m);
    this.name = this.constructor.name;
    this.code = code;
    this.sub = sub;

    if (err instanceof Error) {
      this.stack = err.stack;
    } else {
      this.stack = (new Error(m)).stack;
    }
  }

  static getCode(err: unknown): ErrorCode | null {
    if (err instanceof AppError) {
      return err.code;
    }

    if (err instanceof Error) {
      return ErrorCode.Internal;
    }

    return null;
  }

  static getSub(err: unknown): string | null {
    if (err instanceof AppError) {
      return err.sub;
    }

    if (err instanceof Error) {
      return ErrorSub.InternalError;
    }

    return null;
  }

  static isError(err: unknown): boolean {
    return (err instanceof Error);
  }

  static newValidationError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Invalid, sub, msg, err);
  }

  static newConflictError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Conflict, sub, msg, err);
  }

  static newPermissionError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Permission, sub, msg, err);
  }

  static newUnauthorizedError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Unauthorized, sub, msg, err);
  }

  static newNotFoundError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.NotFound, sub, msg, err);
  }

  static newLimitError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.LimitReached, sub, msg, err);
  }

  static newInternalError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Internal, sub, msg, err);
  }

  static newServerError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Server, sub, msg, err);
  }

  static newNetworkError(sub: string, msg?: string, err?: Error) {
    return new AppError(ErrorCode.Network, sub, msg, err);
  }
}
