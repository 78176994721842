<template>
  <div class="flex min-h-full items-center justify-center p-0 text-center">
      <div class="sm:max-w-lg">
        <img
          src="/icons/warning.svg"
          class="mx-auto h-10 w-10"
          alt="Warning"
        />

        <div class="mt-4 text-center sm:mt-5">
          <h3 class="text-lg font-medium tracking-tight leading-6 text-gray-900 sm:text-xl">
            <slot name="title" />
          </h3>
          <div class="mt-2 text-sm text-gray-500">
            <slot name="text" />
          </div>
        </div>
      <div class="mt-5 sm:mt-6 sm:w-1/2 mx-auto">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>
