import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Plan, PlanId, Subscription } from '@/models';
import { AppError } from '@/lib/error';
import { unixToDate } from '@/lib/dt';
import { Container } from 'typedi';
import { BillingProvider, SubscriptionResult } from '@/provider/api';
import { Either, isLeft, right } from '@/lib/either';
import { SubscriptionCalculation } from './billing.types';

export const useBillingStore = defineStore('billing', () => {
  const billProvider = Container.get(BillingProvider);

  const subscription = ref<Subscription>();
  const planList = ref<Plan[]>([]);

  function reset() {
    subscription.value = undefined;
    planList.value = [];
  }

  function planById(id: PlanId): Plan | undefined {
    return planList.value.find((p) => p.id === id);
  }

  async function getPlanList(): Promise<Either<AppError, Plan[]>> {
    const result = await billProvider.getPlanList();

    if (isLeft(result)) {
      return result;
    }

    const plans = result.right.map((p) => new Plan(p));
    planList.value = plans;

    return right(plans);
  }

  function setSubscription(data: SubscriptionResult) {
    subscription.value = new Subscription({
      id: data.id,
      planId: data.planId,
      status: data.status,
      seats: data.seats,
      seatsUsed: data.seatsUsed,
      cancelAt: data.cancelAt > 0 ? new Date(data.cancelAt * 1000) : undefined,
      periodEnd: new Date(data.periodEnd * 1000),
      createdAt: new Date(data.createdAt * 1000),
    });

    return subscription.value;
  }

  async function getSubscription(): Promise<Either<AppError, Subscription>> {
    const result = await billProvider.getSubscription();

    if (isLeft(result)) {
      return result;
    }

    const data = result.right;
    const sub = setSubscription(data);
    return right(sub);
  }

  async function calculateSubscription(
    planId: string,
  ): Promise<Either<AppError, SubscriptionCalculation>> {
    const calcResult = await billProvider.calculateSubscription({
      planId,
    });

    if (isLeft(calcResult)) {
      return calcResult;
    }

    const calc = calcResult.right;
    const result: SubscriptionCalculation = {
      seatAmount: calc.seatAmount,
      currency: calc.currency,
      proration: calc.proration,
      trialDays: calc.trialDays,
      createdAt: unixToDate(calc.createdAt),
      periodStart: unixToDate(calc.periodStart),
      periodEnd: unixToDate(calc.periodEnd),
    };

    return right(result);
  }

  async function updateSubscriptionSeats(
    seats: number,
  ): Promise<Either<AppError, Subscription>> {
    const result = await billProvider.updateSubscriptionSeats({ seats });

    if (isLeft(result)) {
      return result;
    }

    const data = result.right;
    const sub = setSubscription(data);
    return right(sub);
  }

  async function cancelSubscription(): Promise<Either<AppError, void>> {
    const result = await billProvider.cancelSubscription();

    if (isLeft(result)) {
      return result;
    }

    setSubscription(result.right);
    return right(undefined);
  }

  async function resumeSubscription(): Promise<Either<AppError, void>> {
    const result = await billProvider.resumeSubscription();

    if (isLeft(result)) {
      return result;
    }

    setSubscription(result.right);
    return right(undefined);
  }

  async function startCheckout(
    planId: PlanId,
    seats: number,
    redirectUrl: string,
  ): Promise<Either<AppError, string>> {
    const result = await billProvider.startCheckout({
      planId,
      seats,
      redirectUrl,
    });

    if (isLeft(result)) {
      return result;
    }

    return right(result.right.url);
  }

  async function createPortalSession(
    redirectUrl: string,
  ): Promise<Either<AppError, string>> {
    return billProvider.createPortalSession({ redirectUrl });
  }

  return {
    subscription,
    planList,

    getSubscription,
    calculateSubscription,
    updateSubscriptionSeats,
    cancelSubscription,
    resumeSubscription,
    startCheckout,
    createPortalSession,
    getPlanList,
    planById,
    reset,
  };
});
