export enum AuthRouteName {
  Signup = 'signup',
  Join = 'join',
  Verification = 'verification',
  Onboarding = 'onboarding',
  Login = 'login',
  Logout = 'logout',
  AuthStatus = 'authStatus',
  PasswordReset = 'passwordReset',
}

export enum PageRouteName {
  Index = 'index',
  Dashboard = 'dashboard',
  Team = 'team',
  Settings = 'settings',
  UserSettings = 'userSettings',
  Downloads = 'downloads',
  Billing = 'billing',
}
