import { Service } from 'typedi';
import { ApiProvider } from '@/provider/api/api.provider';
import { left, right, Either } from '@/lib/either';
import { AppError } from '@/lib/error';
import {
  PlanResult,
  UpdateSeatsInput,
  CalculationInput,
  CalculationResult,
  SubscriptionResult,
  CheckoutInput,
  CheckoutResult,
  PortalSessionInput,
} from './billing.types';

@Service()
export class BillingProvider extends ApiProvider {
  async getSubscription(): Promise<Either<AppError, SubscriptionResult>> {
    return this.http
      .request({
        method: 'GET',
        url: '/billing/subscription',
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async calculateSubscription(
    input: CalculationInput,
  ): Promise<Either<AppError, CalculationResult>> {
    return this.http
      .request({
        method: 'POST',
        url: '/billing/subscription/calculate',
        withAuth: true,
        data: input,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async updateSubscriptionSeats(
    input: UpdateSeatsInput,
  ): Promise<Either<AppError, SubscriptionResult>> {
    return this.http
      .request({
        method: 'PUT',
        url: '/billing/subscription/seats',
        withAuth: true,
        data: input,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async cancelSubscription(): Promise<Either<AppError, SubscriptionResult>> {
    return this.http
      .request({
        method: 'POST',
        url: '/billing/subscription/cancel',
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async resumeSubscription(): Promise<Either<AppError, SubscriptionResult>> {
    return this.http
      .request({
        method: 'POST',
        url: '/billing/subscription/resume',
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async startCheckout(
    input: CheckoutInput,
  ): Promise<Either<AppError, CheckoutResult>> {
    return this.http
      .request({
        method: 'POST',
        url: '/billing/subscription/checkout',
        withAuth: true,
        data: input,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async createPortalSession(
    input: PortalSessionInput,
  ): Promise<Either<AppError, string>> {
    return this.http
      .request({
        method: 'POST',
        url: '/billing/portal',
        withAuth: true,
        data: input,
      })
      .then((resp) => right(resp.data.url))
      .catch((err: AppError) => left(err));
  }

  async getPlanList(): Promise<Either<AppError, PlanResult[]>> {
    return this.http
      .request({
        method: 'GET',
        url: '/billing/plans',
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }
}
