import SignupPage from '@/components/Auth/SignupPage.vue';
import LoginPage from '@/components/Auth/LoginPage.vue';
import LogoutPage from '@/components/Auth/LogoutPage.vue';
import ResetPasswordPage from '@/components/Auth/ResetPassword/ResetPasswordView.vue';
import OnboardingPage from '@/components/Auth/OnboardingPage.vue';
import AuthStatusPage from '@/components/Auth/AuthStatusPage.vue';
import { RouteRecordRaw } from 'vue-router';
import { AuthRouteName } from '@/router/route.names';
import VerificationPage from '@/components/Auth/VerificationPage.vue';

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/signup',
    name: AuthRouteName.Signup,
    component: SignupPage,
    meta: {
      guest: true,
    },
  },
  {
    path: '/join',
    name: AuthRouteName.Join,
    component: SignupPage,
    props: (route) => ({
      inviteToken: route.query.t,
    }),
    meta: {
      guest: true,
    },
  },
  {
    path: '/onboarding',
    name: AuthRouteName.Onboarding,
    component: OnboardingPage,
    meta: {
      auth: true,
    },
  },
  {
    path: '/verification',
    name: AuthRouteName.Verification,
    component: VerificationPage,
    props: (route) => ({
      token: route.query.t,
    }),
    meta: {
      auth: true,
    },
  },
  {
    path: '/login',
    name: AuthRouteName.Login,
    component: LoginPage,
    meta: {
      guest: true,
    },
  },
  {
    path: '/auth/status',
    name: AuthRouteName.AuthStatus,
    component: AuthStatusPage,
    props: (route) => ({
      error: route.query.error,
      newUser: !!route.query.new,
    }),
    meta: {
      guest: true,
    },
  },
  {
    path: '/logout',
    name: AuthRouteName.Logout,
    component: LogoutPage,
    meta: {
      auth: true,
    },
  },
  {
    path: '/password/reset',
    name: AuthRouteName.PasswordReset,
    component: ResetPasswordPage,
    props: (route) => ({
      resetToken: route.query.t,
    }),
    meta: {
      guest: true,
    },
  },
];
