export enum UserStatus {
  Active = 'active',
  Invited = 'invited',
}

export enum UserRole {
  Admin = 'admin',
  User = 'user',
}

export interface UserData {
  id: string;
  email: string;
  name?: string;
  status: UserStatus;
  role: UserRole;
  createdAt: number;
}

export class User {
  id: string;
  email: string;
  name?: string;
  role: UserRole;
  status: UserStatus;
  createdAt: Date;

  constructor(data: UserData) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.role = data.role;
    this.status = data.status;
    this.createdAt = new Date(data.createdAt * 1000);
  }

  update(data: UserData): void {
    this.email = data.email;
    this.name = data.name;
    this.role = data.role;
    this.status = data.status;
    this.createdAt = new Date(data.createdAt * 1000);
  }

  isActive(): boolean {
    return this.status === UserStatus.Active;
  }

  isInvited(): boolean {
    return this.status === UserStatus.Invited;
  }

  isAdmin(): boolean {
    return this.role === UserRole.Admin;
  }
}
