export interface AccountData {
  id: string;
  name: string;
  trialUsed: boolean;
  createdAt: number;
}

export class Account {
  id: string;
  name: string;
  trialUsed: boolean;
  createdAt: Date;

  constructor(data: AccountData) {
    this.id = data.id;
    this.name = data.name;
    this.trialUsed = data.trialUsed;
    this.createdAt = new Date(data.createdAt * 1000);
  }
}
