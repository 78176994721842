import { nanoid } from 'nanoid';
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { IApiConfig } from './api.types';

export function createApiClient(config: IApiConfig): AxiosInstance {
  return axios.create({
    baseURL: `${config.address}/v1/`,
    timeout: config.timeout || 15000,
    headers: {
      'X-Request-Id': nanoid(16),
    },
  });
}

export function useRequestInterceptor(
  client: AxiosInstance,
  onFulfilled: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  onRejected: (error: Error) => Error | Promise<Error>,
): void {
  client.interceptors.request.use(onFulfilled, onRejected);
}

export function useResponseInterceptor(
  client: AxiosInstance,
  onFulfilled: (resp: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  onRejected: (error: Error) => Error | Promise<Error>,
): void {
  client.interceptors.response.use(onFulfilled, onRejected);
}
