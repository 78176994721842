export enum ApiErrorCode {
  EINVALID = 'EINVALID',
  ECONFLICT = 'ECONFLICT',
  ENOTFOUND = 'ENOTFOUND',
  ELIMIT = 'ELIMIT',
  EAUTH = 'EAUTH',
  EPERMISSION = 'EPERMISSION',
  EINTERNAL = 'EINTERNAL',
}

export type ApiError = {
  code: ApiErrorCode;
  sub: string;
  message?: string;
};

export interface IApiConfig {
  address: string;
  timeout: number;
}
