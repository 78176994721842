import { Service } from 'typedi';
import { ApiProvider } from '@/provider/api/api.provider';
import { left, right, Either } from '@/lib/either';
import {
  ISignUpInput,
  ISignInInput,
  IResetPasswordInput,
  IChangePasswordInput,
  IAcceptInviteInput,
  IVerifyInvitePayload,
  IAccessPayload,
} from '@/provider/api/auth/auth.types';
import { AppError } from '@/lib/error';

@Service()
export class AuthProvider extends ApiProvider {
  /**
   * User Sign Up
   */
  async signUp(data: ISignUpInput): Promise<Either<AppError, IAccessPayload>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/sign-up',
        withCredentials: true,
        data,
      })
      .then((resp) => right({
        accessToken: resp.data.accessToken,
      }))
      .catch((err: AppError) => left(err));
  }

  /**
   * User Sign In
   */
  async signIn(data: ISignInInput): Promise<Either<AppError, IAccessPayload>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/sign-in',
        withCredentials: true,
        data,
      })
      .then((resp) => right({
        accessToken: resp.data.accessToken,
      }))
      .catch((err: AppError) => left(err));
  }

  /**
   * Sign out
   */
  async signOut(): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/sign-out',
        withCredentials: true,
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  /**
   * Accept invite to account
   */
  async acceptInvite(data: IAcceptInviteInput): Promise<Either<AppError, IAccessPayload>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/accept-invite',
        withCredentials: true,
        data,
      })
      .then((resp) => right({
        accessToken: resp.data.accessToken,
      }))
      .catch((err: AppError) => left(err));
  }

  /**
   * Refresh access token
   */
  async refreshToken(): Promise<Either<AppError, IAccessPayload>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/access/refresh',
        withCredentials: true,
      })
      .then((resp) => right({
        accessToken: resp.data.accessToken,
      }))
      .catch((err: AppError) => left(err));
  }

  /**
   * Start email verification
   */
  async createEmailVerificationCode(): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/code/email-verification',
        withCredentials: true,
        withAuth: true,
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  /**
   * Complete email verification
   */
  async verifyEmailVerificationCode(
    token: string,
  ): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/code/email-verification/verify',
        data: { token },
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  /**
   * Initiate password reset
   */
  async createResetPasswordCode(email: string): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/code/password-reset',
        data: { email },
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  /**
   * Verify password reset token
   */
  async verifyResetPasswordCode(token: string): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/code/password-reset/verify',
        data: { token },
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  /**
   * Verify invite token
   */
  async verifyUserInviteCode(token: string): Promise<Either<AppError, IVerifyInvitePayload>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/code/user-invite/verify',
        data: { token },
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  /**
   * Reset password
   */
  async resetPassword(data: IResetPasswordInput): Promise<Either<AppError, null>> {
    return this.http
      .request({
        method: 'POST',
        url: '/auth/password',
        data,
      })
      .then(() => right(null))
      .catch((err: AppError) => left(err));
  }

  async changePassword(data: IChangePasswordInput): Promise<Either<AppError, IAccessPayload>> {
    return this.http
      .request({
        method: 'PUT',
        url: '/auth/password',
        withCredentials: true,
        withAuth: true,
        data,
      })
      .then((resp) => right({
        accessToken: resp.data.accessToken,
      }))
      .catch((err: AppError) => left(err));
  }
}
