export interface IAppConfig {
  passwordRules: {
    minLength: number;
    minLowercase: number;
    minUppercase: number,
    minNumbers: number,
    minSymbols: number,
  }
}

export default (): IAppConfig => ({
  passwordRules: {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
  },
});
