import { computed, ref } from 'vue';
import { Router } from 'vue-router';
import { defineStore } from 'pinia';
import { Container } from 'typedi';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AppError } from '@/lib/error';
import { isLeft } from '@/lib/either';
import { useDeviceType } from '@/composables/device-type';
import { RpcMethod, useExtensionCall } from '@/composables/extension';
import { AuthProvider } from '@/provider/api';
import { AuthRouteName, PageRouteName } from '@/router/route.names';
import { UserRole } from '@/models';
import { VerifyInviteResult } from './auth.types';

const TOKEN_TTL_GAP = 60; // seconds

interface CherryJwtPayload extends JwtPayload {
  sub: string;
  aid: string;
  uro: UserRole;
  exp: number;
  iat: number;
}

export const useAuthStore = defineStore('auth', () => {
  const authProvider = Container.get(AuthProvider);
  const router = Container.get<Router>('router');
  const extension = useExtensionCall(
    process.env.VUE_APP_EXTENSION_ID,
  );

  const accountId = ref('');
  const userId = ref('');
  const userRole = ref(UserRole.User);
  const accessToken = ref('');
  const tokenIss = ref(0);
  const tokenTtl = ref(0);
  const lastEmail = ref('');

  const isAdmin = computed(() => userRole.value === UserRole.Admin);
  const isAuthenticated = computed(() => !!accessToken.value);

  function reset() {
    accountId.value = '';
    userId.value = '';
    userRole.value = UserRole.User;
    accessToken.value = '';
    tokenIss.value = 0;
    tokenTtl.value = 0;
  }

  function setAccessToken(newToken: string): void {
    const now = Math.floor(Date.now() / 1000);
    let payload;

    try {
      payload = jwtDecode<CherryJwtPayload>(newToken);
    } catch (err) {
      return;
    }

    accountId.value = payload.aid;
    userId.value = payload.sub;
    userRole.value = payload.uro;
    accessToken.value = newToken;
    tokenIss.value = now;
    tokenTtl.value = payload.exp - payload.iat;
  }

  function isTokenExpired(): boolean {
    const now = Math.floor(Date.now() / 1000);
    const tokenTs = tokenIss.value;
    const ttl = tokenTtl.value;

    if (!tokenTs || !ttl) {
      return true;
    }

    const diff = now - tokenTs + TOKEN_TTL_GAP;
    return diff >= ttl || Math.abs(diff) < TOKEN_TTL_GAP;
  }

  async function singUp(email: string, password: string): Promise<AppError | null> {
    const result = await authProvider.signUp({ email, password });

    if (isLeft(result)) {
      return result.left;
    }

    lastEmail.value = email;
    setAccessToken(result.right.accessToken);
    const device = useDeviceType();

    switch (device) {
      case 'desktop':
        await router.replace({ name: AuthRouteName.Onboarding });
        break;
      default:
        await router.replace({ name: PageRouteName.Dashboard });
        break;
    }

    return null;
  }

  async function signIn(email: string, password: string): Promise<AppError | null> {
    const result = await authProvider.signIn({ email, password });

    if (isLeft(result)) {
      return result.left;
    }

    lastEmail.value = email;
    setAccessToken(result.right.accessToken);
    await router.replace({ name: PageRouteName.Dashboard });

    return null;
  }

  async function signOut(): Promise<AppError | null> {
    const result = await authProvider.signOut();
    const oldUserId = userId.value;
    reset();

    await router.replace({ name: AuthRouteName.Login });

    if (isLeft(result)) {
      return result.left;
    }

    await extension.call({
      method: RpcMethod.UserSignOut,
      params: { userId: oldUserId },
    });

    return null;
  }

  async function startPasswordReset(email: string): Promise<AppError | null> {
    const result = await authProvider.createResetPasswordCode(email);

    if (isLeft(result)) {
      return result.left;
    }

    lastEmail.value = email;
    return null;
  }

  async function verifyResetPasswordToken(resetToken: string): Promise<AppError | null> {
    const result = await authProvider.verifyResetPasswordCode(resetToken);

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  async function resetPassword(restToken: string, password: string): Promise<AppError | null> {
    const result = await authProvider.resetPassword({ token: restToken, password });

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<AppError | null> {
    const result = await authProvider.changePassword({ oldPassword, newPassword });

    if (isLeft(result)) {
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    return null;
  }

  async function verifyInvite(inviteToken: string): Promise<AppError | VerifyInviteResult> {
    const result = await authProvider.verifyUserInviteCode(inviteToken);

    if (isLeft(result)) {
      return result.left;
    }

    return result.right;
  }

  async function acceptInvite(
    inviteToken: string,
    password: string,
  ): Promise<AppError | null> {
    const result = await authProvider.acceptInvite({
      password,
      token: inviteToken,
    });

    if (isLeft(result)) {
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    await router.replace({ name: AuthRouteName.Onboarding });

    return null;
  }

  async function refreshToken(): Promise<AppError | null> {
    const result = await authProvider.refreshToken();

    if (isLeft(result)) {
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    return null;
  }

  async function verifyEmail(token: string): Promise<AppError | null> {
    const result = await authProvider.verifyEmailVerificationCode(token);

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  return {
    accountId,
    userId,
    accessToken,
    tokenIss,
    tokenTtl,
    userRole,
    lastEmail,

    isAuthenticated,
    isAdmin,

    singUp,
    signIn,
    signOut,
    refreshToken,
    isTokenExpired,
    verifyInvite,
    acceptInvite,
    verifyEmail,
    startPasswordReset,
    verifyResetPasswordToken,
    resetPassword,
    changePassword,
    reset,
  };
}, {
  persist: {
    key: 'cherry:auth',
    paths: ['lastEmail', 'accessToken'],
  },
});
