import { InternalAxiosRequestConfig } from 'axios';
import { AppError } from '@/lib/error';

interface IAuthStore {
  accessToken: string | undefined;
  isTokenExpired(): boolean;
  refreshToken(): Promise<AppError | null>;
}

export const authInterceptor = (store: IAuthStore) => (
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    if (!config.withAuth) {
      return config;
    }

    if (!store.accessToken) {
      throw AppError.newUnauthorizedError('invalid_token', 'access token required');
    }

    if (store.isTokenExpired()) {
      const err = await store.refreshToken();

      if (err) {
        throw err;
      }
    }

    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${store.accessToken}`;

    return config;
  }
);
