import { Service } from 'typedi';
import { ApiProvider } from '@/provider/api/api.provider';
import { left, right, Either } from '@/lib/either';
import { AppError } from '@/lib/error';
import {
  IUserInfo,
  IUpdateUserInfoInput,
  IUpdateUserRoleInput,
} from '@/provider/api/user/user.types';

@Service()
export class UserProvider extends ApiProvider {
  /**
   * Get user info
   */
  async findUserById(userId: string): Promise<Either<AppError, IUserInfo>> {
    return this.http
      .request({
        method: 'GET',
        url: `/users/${userId}`,
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  /**
   * List all users
   */
  async listUsers(): Promise<Either<AppError, IUserInfo[]>> {
    return this.http
      .request({
        method: 'GET',
        url: '/users',
        withAuth: true,
      })
      .then((resp) => right(resp.data.results))
      .catch((err: AppError) => left(err));
  }

  /**
   * Invite new user
   */
  async inviteUser(email: string): Promise<Either<AppError, IUserInfo>> {
    return this.http
      .request({
        method: 'POST',
        url: '/users',
        withAuth: true,
        data: { email },
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async updateUserInfo(data: IUpdateUserInfoInput): Promise<Either<AppError, IUserInfo>> {
    return this.http
      .request({
        method: 'POST',
        url: `/users/${data.userId}`,
        withAuth: true,
        data,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async updateUserRole(data: IUpdateUserRoleInput): Promise<Either<AppError, IUserInfo>> {
    return this.http
      .request({
        method: 'POST',
        url: `/users/${data.userId}/role`,
        withAuth: true,
        data,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }

  async deleteUser(userId: string): Promise<Either<AppError, void>> {
    return this.http
      .request({
        method: 'DELETE',
        url: `/users/${userId}`,
        withAuth: true,
      })
      .then(() => right(undefined))
      .catch((err: AppError) => left(err));
  }
}
