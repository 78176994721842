import { Plan, PlanId } from './plan.model';

export enum SubStatus {
  Incomplete = 'incomplete',
  Active = 'active',
  Inactive = 'inactive',
  Trialing = 'trialing',
}

export interface SubscriptionData {
  id: string;
  planId: PlanId;
  status: SubStatus;
  seats: number;
  seatsUsed: number;
  cancelAt?: Date;
  periodEnd: Date;
  createdAt: Date;
}

export class Subscription {
  id: string;
  planId: PlanId;
  status: SubStatus;
  seats: number;
  seatsUsed: number;
  cancelAt?: Date;
  periodEnd: Date;
  createdAt: Date;

  constructor(data: SubscriptionData) {
    this.id = data.id;
    this.planId = data.planId;
    this.status = data.status;
    this.seats = data.seats;
    this.seatsUsed = data.seatsUsed;
    this.cancelAt = data.cancelAt;
    this.periodEnd = data.periodEnd;
    this.createdAt = data.createdAt;
  }

  sameSeats(seats: number): boolean {
    return this.seats === seats;
  }

  hasFreeSeats(): boolean {
    return this.seatsUsed < this.seats;
  }

  isPro(): boolean {
    return this.isRunning() && Plan.oneOfPro(this.planId);
  }

  isPaidPro(): boolean {
    return this.isPro() && !this.isTrial();
  }

  isFree(): boolean {
    return this.isRunning() && !Plan.oneOfPro(this.planId);
  }

  isTrial(): boolean {
    return this.status === SubStatus.Trialing;
  }

  isRunning(): boolean {
    switch (this.status) {
      case SubStatus.Active:
      case SubStatus.Trialing:
        return true;
      default:
        return false;
    }
  }

  willBeCanceled(): boolean {
    return !!this.cancelAt && this.cancelAt > new Date();
  }
}
