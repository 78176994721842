import { Either, left, right } from '@/lib/either';
import { AppError } from '@/lib/error';
import { Currency, currencySymbol } from './currency';

const zeroDecimal = {
  [Currency.BIF]: true,
  [Currency.CLP]: true,
  [Currency.DJF]: true,
  [Currency.GNF]: true,
  [Currency.JPY]: true,
  [Currency.KMF]: true,
  [Currency.KRW]: true,
  [Currency.MGA]: true,
  [Currency.PYG]: true,
  [Currency.RWF]: true,
  [Currency.VND]: true,
  [Currency.VUV]: true,
  [Currency.XAF]: true,
  [Currency.XOF]: true,
  [Currency.XPF]: true,
};

interface MoneyValue {
  amount: number;
  currency: Currency;
}

// We store all amounts as integers in cents
// Some currencies have zero decimals, so we store them as is if needed
// 1$ = 100, ¥500 = 500
export class Money {
  readonly amount: number;
  readonly currency: Currency;

  constructor(amount: number, currency: Currency) {
    this.amount = amount;
    this.currency = currency;
  }

  static zero(currency: Currency): Money {
    return new Money(0, currency);
  }

  static sum(...moneys: Money[]): Money {
    const { currency } = moneys[0];
    const amount = moneys.reduce((sum, m) => sum + m.amount, 0);
    return new Money(amount, currency);
  }

  private checkCurrency(m: MoneyValue): AppError | undefined {
    if (!this.sameCurrency(m.currency)) {
      return AppError.newConflictError('currency_mismatch');
    }

    return undefined;
  }

  sameCurrency(currency: Currency): boolean {
    return this.currency === currency;
  }

  add(m: MoneyValue): Either<AppError, Money> {
    const error = this.checkCurrency(m);
    if (error) {
      return left(error);
    }

    const amount = Math.round(this.amount + m.amount);
    return right(new Money(amount, this.currency));
  }

  sub(m: MoneyValue): Either<AppError, Money> {
    const error = this.checkCurrency(m);
    if (error) {
      return left(error);
    }

    const amount = Math.round(this.amount + m.amount);
    return right(new Money(amount, this.currency));
  }

  multiply(m: number): Money {
    const amount = Math.round(this.amount * m);
    return new Money(amount, this.currency);
  }

  divide(d: number): Money {
    const amount = Math.round(this.amount / d);
    return new Money(amount, this.currency);
  }

  getPercent(p: number): Money {
    const amount = Math.round((this.amount * p) / 100);
    return new Money(amount, this.currency);
  }

  gte(val: number): boolean {
    return this.amount >= val;
  }

  toDecimal(precision = 2): number {
    if (this.currency in zeroDecimal) {
      return this.amount;
    }

    return parseFloat((this.amount / 100).toFixed(precision));
  }

  toString(): string {
    const symbol = currencySymbol(this.currency);
    const amount = this.toDecimal();

    return `${symbol}${amount}`;
  }
}
