import { Service } from 'typedi';
import { ApiProvider } from '@/provider/api/api.provider';
import { left, right, Either } from '@/lib/either';
import { AppError } from '@/lib/error';
import { IAccountInfo } from './account.types';

@Service()
export class AccountProvider extends ApiProvider {
  /**
   * Get account info
   */
  async getAccountInfo(): Promise<Either<AppError, IAccountInfo>> {
    return this.http
      .request({
        method: 'GET',
        url: '/account/info',
        withAuth: true,
      })
      .then((resp) => right(resp.data))
      .catch((err: AppError) => left(err));
  }
}
