import { createI18n } from 'vue-i18n';
import locales from '@/i18n/locales';

export default createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales,
  availableLocales: ['en'],
  datetimeFormats: {
    en: {
      short: { month: 'short', day: 'numeric' },
      shortFull: { month: 'short', day: 'numeric', year: 'numeric' },
      base: { month: 'long', day: 'numeric' },
      baseFull: { month: 'long', day: 'numeric', year: 'numeric' },
    },
  },
});
