import { isAxiosError, AxiosError } from 'axios';
import { AppError, ErrorSub } from '@/lib/error';
import { ApiError, ApiErrorCode } from '@/provider/api/api.types';

export const errorInterceptor = () => (err: Error) => {
  if (!isAxiosError(err)) {
    return Promise.reject(AppError.newInternalError(
      ErrorSub.InternalError,
      'api: unexpected error',
      err,
    ));
  }

  if (err.config?.raw) {
    return Promise.reject(err);
  }

  switch (err.code) {
    case AxiosError.ERR_NETWORK:
    case AxiosError.ECONNABORTED:
    case AxiosError.ETIMEDOUT:
      return Promise.reject(AppError.newNetworkError(
        ErrorSub.InternalError,
        'api: network error',
        err,
      ));
    case AxiosError.ERR_FR_TOO_MANY_REDIRECTS:
      return Promise.reject(AppError.newServerError(
        ErrorSub.ServerError,
        'api: to many redirects',
        err,
      ));
    case AxiosError.ERR_CANCELED:
      return Promise.reject(AppError.newInternalError(
        ErrorSub.InternalError,
        'api: request canceled',
        err,
      ));
    default:
      break;
  }

  const apiError = err.response?.data?.error as ApiError;
  const details = apiError?.message || err.message;
  let result: AppError;

  switch (apiError?.code) {
    case ApiErrorCode.EINVALID:
      result = AppError.newValidationError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.ECONFLICT:
      result = AppError.newConflictError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.ENOTFOUND:
      result = AppError.newNotFoundError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.ELIMIT:
      result = AppError.newLimitError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.EAUTH:
      result = AppError.newUnauthorizedError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.EPERMISSION:
      result = AppError.newPermissionError(apiError.sub, `api: ${details}`);
      break;
    case ApiErrorCode.EINTERNAL:
      result = AppError.newServerError(apiError.sub, `api: ${details}`);
      break;
    default:
      result = AppError.newServerError(ErrorSub.ServerError, 'api: unknown server error', err);
      break;
  }

  return Promise.reject(result);
};
